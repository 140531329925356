"use strict";
var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { createAction } from "@reduxjs/toolkit";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { AppReducerMapBuilder } from "./util";
import { transformErrorToMessageString } from "../../../../common/utils";
import { updatePanel } from "../../core/api/analyzer";
import * as norm from "../../schema/normalized";
import { succeeded, LOADING } from "../../schema/fetched";
import { sendNotification } from "./notifications.module";
import notificationMessages from "../../messages/notification.message";
import * as notification from "../../core/notifications";
import { startSubmit, stopSubmit } from "redux-form";
import { closeModal } from "./modals.module";
import { ANALYZER_SESSION_PANEL_FORM } from "../constants/analyzer.form.constants";
import { ANALYZER_SESSION_PANEL_EDIT } from "../constants/modals.constants";
import { createAdditionalAnomalyPanel, createPanelSignal } from "../../schema/analyzeSessions";
import { getPanelById, getSignalsByPanelId } from "../selectors/analyzer.selector";
import { transformSignal } from "./analyzer.sessions.panel.create.module";
export const updateSessionPanel = createAction(
  "update session panel",
  (sessionId, panelId, data) => ({
    payload: { sessionId, panelId, data }
  })
);
export const updateSessionPanelSuccess = createAction("update session panel success", (panel) => ({
  payload: panel
}));
export const updateSessionPanelPartial = createAction(
  "update session panel and flag as updated",
  (panelId, data) => ({
    payload: { panelId, data }
  })
);
export const updateSessionPanelAddSignal = createAction(
  "update session panel attributes",
  (panelId, data) => ({
    payload: { panelId, data }
  })
);
export const updateSessionPanelSignalOrder = createAction(
  "update signal order",
  (panelId, list) => ({
    payload: { panelId, list }
  })
);
export const updateSessionPanelSignal = createAction(
  "update session panel signal",
  (panelId, panelSignalId, data) => ({
    payload: { panelId, panelSignalId, data }
  })
);
export const removeSessionPanelSignal = createAction(
  "remove session panel signal",
  (panelSignalId, panelId) => ({
    payload: { panelSignalId, panelId }
  })
);
export const getNextPanelSignalOrder = (state, panelId) => {
  const filteredPanelSignals = state.analyzer.panelSignals.allIds.filter((signalId) => state.analyzer.panelSignals.byId[signalId].data.panelId === panelId).map((signalId) => state.analyzer.panelSignals.byId[signalId].data.order);
  return filteredPanelSignals.length ? Math.max(...filteredPanelSignals) + 1 : 0;
};
export const cloneAnomalyPanel = createAction("clone anomaly panel", (panelId) => ({
  payload: { panelId }
}));
export const deleteAnomalyPanel = createAction("delete anomaly panel", (panelId) => ({
  payload: { panelId }
}));
export const createAnomalyPanel = createAction("create anomaly panel", (name) => ({
  payload: { name }
}));
export const reducer = AppReducerMapBuilder.new().add(updateSessionPanel, (state, { panelId }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: norm.update(state.analyzer.panels, panelId, __spreadProps(__spreadValues({}, LOADING), {
      data: state.analyzer.panels.byId[panelId].data
    }))
  })
})).add(updateSessionPanelSuccess, (state, panel) => {
  const currentPanel = state.analyzer.panels.byId[panel.id].data;
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: norm.update(
        state.analyzer.panels,
        panel.id,
        succeeded(__spreadProps(__spreadValues({}, panel), {
          settings: currentPanel.settings,
          tool: currentPanel.tool,
          time: currentPanel.time
        }))
      )
    })
  });
}).add(updateSessionPanelPartial, (state, { panelId, data }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
      data: __spreadValues(__spreadValues({}, state.analyzer.panels.byId[panelId].data), data),
      status: "updated"
    }))
  })
})).add(updateSessionPanelAddSignal, (state, { panelId, data }) => {
  const addedSignal = state.analyzer.signals.data.filter(
    (signal) => signal.id === data.signalId
  )[0];
  const order = getNextPanelSignalOrder(state, panelId);
  const insertedSignal = createPanelSignal(panelId, order, addedSignal);
  let normalizedPanelSignals = state.analyzer.panelSignals;
  normalizedPanelSignals = __spreadValues({}, norm.upsert(normalizedPanelSignals, insertedSignal.id, succeeded(insertedSignal)));
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
        data: __spreadValues({}, state.analyzer.panels.byId[panelId].data),
        status: "updated"
      })),
      panelSignals: normalizedPanelSignals
    })
  });
}).add(updateSessionPanelSignalOrder, (state, { panelId, list }) => {
  const panelSignals = state.analyzer.panelSignals.byId;
  list.map((item) => {
    panelSignals[item.id] = __spreadProps(__spreadValues({}, panelSignals[item.id]), {
      data: __spreadProps(__spreadValues({}, panelSignals[item.id].data), {
        order: item.order
      })
    });
  });
  const updatedPanelSignals = __spreadProps(__spreadValues({}, state.analyzer.panelSignals), {
    byId: panelSignals
  });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, {
        data: __spreadValues({}, state.analyzer.panels.byId[panelId].data),
        status: "updated"
      })),
      panelSignals: updatedPanelSignals
    })
  });
}).add(updateSessionPanelSignal, (state, { panelSignalId, panelId, data }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, __spreadProps(__spreadValues({}, state.analyzer.panels.byId[panelId]), {
      status: "updated"
    }))),
    panelSignals: __spreadValues({}, norm.update(state.analyzer.panelSignals, panelSignalId, {
      data: __spreadValues(__spreadValues({}, state.analyzer.panelSignals.byId[panelSignalId].data), data)
    }))
  })
})).add(removeSessionPanelSignal, (state, { panelSignalId, panelId }) => __spreadProps(__spreadValues({}, state), {
  analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
    panelSignals: __spreadValues({}, norm.remove(state.analyzer.panelSignals, panelSignalId)),
    panels: __spreadValues({}, norm.update(state.analyzer.panels, panelId, __spreadProps(__spreadValues({}, state.analyzer.panels.byId[panelId]), {
      status: "updated"
    })))
  })
})).add(cloneAnomalyPanel, (state, { panelId }) => {
  const newPanelId = Math.max(...state.analyzer.panels.allIds) + 1;
  const panel = state.analyzer.panels.byId[panelId].data;
  const panelSignals = state.analyzer.panelSignals.allIds.filter((id) => state.analyzer.panelSignals.byId[id].data.panelId === panelId).map((id) => state.analyzer.panelSignals.byId[id].data);
  let normalizedPanelSignals = state.analyzer.panelSignals;
  panelSignals.forEach((panelSignal) => {
    const { signal, color, interpolationType, height, chartType } = panelSignal;
    const newSignal = createPanelSignal(newPanelId, panelSignal.order, signal, {
      color,
      interpolationType,
      height,
      chartType
    });
    normalizedPanelSignals = __spreadValues({}, norm.upsert(normalizedPanelSignals, newSignal.id, succeeded(newSignal)));
  });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues(__spreadValues({}, state.analyzer.panels), norm.upsert(
        state.analyzer.panels,
        newPanelId,
        succeeded(__spreadProps(__spreadValues({}, panel), {
          id: newPanelId,
          name: `${panel.name} (copy)`
        }))
      )),
      panelSignals: normalizedPanelSignals
    })
  });
}).add(deleteAnomalyPanel, (state, { panelId }) => {
  const panelSignals = state.analyzer.panelSignals.allIds.filter((id) => state.analyzer.panelSignals.byId[id].data.panelId === panelId).map((id) => state.analyzer.panelSignals.byId[id].data);
  let normalizedPanelSignals = state.analyzer.panelSignals;
  panelSignals.forEach((panelSignal) => {
    normalizedPanelSignals = __spreadValues({}, norm.remove(normalizedPanelSignals, panelSignal.id));
  });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: norm.remove(state.analyzer.panels, panelId),
      panelSignals: normalizedPanelSignals
    })
  });
}).add(createAnomalyPanel, (state, { name }) => {
  const newPanelId = Math.max(...state.analyzer.panels.allIds) + 1;
  const panel = createAdditionalAnomalyPanel({ id: newPanelId, name });
  return __spreadProps(__spreadValues({}, state), {
    analyzer: __spreadProps(__spreadValues({}, state.analyzer), {
      panels: __spreadValues(__spreadValues({}, state.analyzer.panels), norm.upsert(state.analyzer.panels, newPanelId, succeeded(panel)))
    })
  });
}).build();
export function* updateSessionPanelSaga({ payload: { sessionId, panelId, data } }) {
  if (data.name) {
    yield put(startSubmit(ANALYZER_SESSION_PANEL_FORM));
    const { response, error } = yield call(updatePanel, sessionId, panelId, data);
    yield put(stopSubmit(ANALYZER_SESSION_PANEL_FORM));
    yield put(closeModal(ANALYZER_SESSION_PANEL_EDIT));
    if (response) {
      yield put(updateSessionPanelSuccess(response));
      yield put(
        sendNotification(
          notificationMessages.success_title,
          notificationMessages.session_panel_updated_description,
          notification.NOTIFICATION_EVENT
        )
      );
    } else {
      const errorString = transformErrorToMessageString(error);
      yield put(
        sendNotification(
          __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
            values: { error: errorString }
          }),
          null,
          notification.NOTIFICATION_ERROR
        )
      );
    }
  } else {
    const state = yield select();
    const panelData = yield call(getPanelById, state, panelId);
    const signals = yield call(getSignalsByPanelId, state, panelId);
    const transformedSignals = signals.map(transformSignal);
    const updatedData = {
      rangeDeltaMs: panelData.rangeDeltaMs,
      rangeFrom: panelData.rangeFrom,
      panelSignals: transformedSignals
    };
    const { response, error } = yield call(updatePanel, sessionId, panelId, updatedData);
    if (response) {
      yield put(updateSessionPanelSuccess(response));
      yield put(
        sendNotification(
          notificationMessages.success_title,
          notificationMessages.session_panel_updated_description,
          notification.NOTIFICATION_EVENT
        )
      );
    } else {
      const errorString = transformErrorToMessageString(error);
      yield put(
        sendNotification(
          __spreadProps(__spreadValues({}, notificationMessages.something_happened), {
            values: { error: errorString }
          }),
          null,
          notification.NOTIFICATION_ERROR
        )
      );
    }
  }
}
export function* watchUpdateSessionPanel() {
  yield takeEvery(updateSessionPanel, updateSessionPanelSaga);
}
